'use client'

import { ImageMediaOnlyField } from '@pienso/models'
import { useMediaQuery } from '@pienso/styles'
import dynamic from 'next/dynamic'
import { useState, type CSSProperties } from 'react'
import { useInView } from 'react-intersection-observer'
import { Media } from '../../Media'
import * as s from './SplineScene.css'

const Spline = dynamic(() => import('@splinetool/react-spline'))

interface SplineSceneProps {
  url: string
  placeholder?: ImageMediaOnlyField
  className?: string
  isMobile?: boolean
  isMobileAndDesktop?: boolean
  sizes?: string
}

function SplineScene({
  url,
  placeholder,
  sizes,
  className,
  isMobile,
  isMobileAndDesktop,
}: SplineSceneProps) {
  const [isHidden, setIsHidden] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)
  const isMd = useMediaQuery('md')

  const { ref } = useInView({
    threshold: 0,
    rootMargin: '350px 0px 350px 0px',
    onChange(inView) {
      if (inView) {
        setIsHidden(false)
      } else {
        setIsHidden(true)
        setIsLoaded(false)
      }
    },
  })

  if (!isMobileAndDesktop) {
    if (isMobile && isMd) return null
    if (!isMobile && !isMd) return null
  }

  const inlineSplineStyles: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
  }

  return (
    <div ref={ref} className={s.wrapper}>
      {!isHidden && (
        <Spline
          scene={url}
          className={className}
          style={inlineSplineStyles}
          renderOnDemand
          onLoad={() => {
            setIsLoaded(true)
          }}
        />
      )}

      {placeholder && placeholder.mediaPayload && (
        <Media
          mediaPayload={placeholder.mediaPayload}
          className={s.placeholder({ isVisible: isHidden || !isLoaded })}
          layout="fill"
          sizes={sizes}
        />
      )}
    </div>
  )
}

export { SplineScene }
