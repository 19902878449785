import { HTMLAttributes } from 'react'
import { shopifyImageLoader } from '../helpers/shopifyImageLoader'
import { Image } from './Image'
import { MediaProps } from '../models/MediaProps'
import { ratios } from '../ratios'
import { ShopifyResolvedVideo } from '@pienso/shopify-sdk'
import * as s from './ShopifyVideo.css'

type ShopifyVideoProps = Omit<MediaProps, 'mediaPayload'> & {
  /**
   * An object with fields that correspond to the Storefront API's [ExternalVideo object](https://shopify.dev/api/storefront/reference/products/externalvideo).
   */
  mediaPayload: ShopifyResolvedVideo['video']
  /** An object of image size options for the video's `previewImage`. Uses `shopifyImageLoader` to generate the `poster` URL. */
  previewImageOptions?: Parameters<typeof shopifyImageLoader>[0]
  /** Props that will be passed to the `video` element's `source` children elements. */
  sourceProps?: HTMLAttributes<HTMLSourceElement>
  priority?: boolean
}

/**
 * The `Video` component renders a `video` for the Storefront API's [Video object](https://shopify.dev/api/storefront/reference/products/video).
 */
export function ShopifyVideo(
  props: JSX.IntrinsicElements['video'] & ShopifyVideoProps,
) {
  const {
    mediaPayload,
    priority,
    previewImageOptions = {},
    id = mediaPayload.id,
    playsInline = true,
    controls = false,
    sourceProps = {},
    layout,
    loop = true,
    ratio,
  } = props

  const posterUrl = shopifyImageLoader({
    src: mediaPayload.previewImage?.url ?? '',
    // TODO: fix required width
    width: mediaPayload.previewImage?.width || 0,
    ...previewImageOptions,
  })

  if (!mediaPayload.sources) {
    throw new Error(`<Video/> requires a 'data.sources' array`)
  }

  const height = mediaPayload.previewImage?.height || 1
  const width = mediaPayload.previewImage?.width || 1

  const containerStyles = (() => {
    if (layout !== 'responsive') return undefined

    if (ratio && typeof ratio === 'string') {
      const ratioValue = ratios[ratio]
      const paddingBottom = `${ratioValue * 100}%`
      return { paddingBottom }
    }

    const originalRatio = `${(height / width) * 100}%`
    return { paddingBottom: originalRatio }
  })()

  return (
    <div style={containerStyles} className={s.videoWrap({ layout })}>
      <Image
        src={posterUrl}
        priority={priority}
        layout={'fill'}
        alt={mediaPayload.alt || ''}
      />
      <video
        className={s.videoElement}
        autoPlay
        muted
        loop={loop}
        id={id}
        playsInline={playsInline}
        controls={controls}
        poster={posterUrl}
      >
        {mediaPayload.sources.map((source) => {
          if (!(source?.url && source?.mimeType)) {
            throw new Error(`<Video/> needs 'source.url' and 'source.mimeType'`)
          }
          return (
            <source
              {...sourceProps}
              key={source.url}
              src={source.url}
              type={source.mimeType}
            />
          )
        })}
      </video>
    </div>
  )
}
