import {
  YouTube,
  Vimeo,
  useEmbeddedVideoUrl,
} from '../helpers/externalVideoParameters'
import { MediaProps } from '../models/MediaProps'
import { Image } from './Image'
import { ShopifyResolvedExternalVideo } from '@pienso/shopify-sdk'
import * as s from './ShopifyExternalVideo.css'

type ShopifyExternalVideoProps = Omit<MediaProps, 'mediaPayload'> & {
  /**
   * An object with fields that correspond to the Storefront API's [ExternalVideo object](https://shopify.dev/api/storefront/reference/products/externalvideo).
   */
  mediaPayload: ShopifyResolvedExternalVideo['video']
  /** An object containing the options available for either
   * [YouTube](https://developers.google.com/youtube/player_parameters#Parameters) or
   * [Vimeo](https://vimeo.zendesk.com/hc/en-us/articles/360001494447-Using-Player-Parameters).
   */
  options?: YouTube | Vimeo
}

type PropsWeControl = 'src'

/**
 * The `ExternalVideo` component renders an embedded video for the Storefront
 * API's [ExternalVideo object](https://shopify.dev/api/storefront/reference/products/externalvideo).
 */
export function ShopifyExternalVideo(
  props: Omit<JSX.IntrinsicElements['iframe'], PropsWeControl> &
    ShopifyExternalVideoProps,
) {
  const {
    mediaPayload,
    options,
    id = mediaPayload.id,
    frameBorder = '0',
    allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
    allowFullScreen = true,
    loading = 'lazy',
    layout,
    priority,
    loop = true,
  } = props

  if (!mediaPayload.embedUrl) {
    throw new Error(`<ExternalVideo/> requires the 'embedUrl' property`)
  }

  // default ext video options
  const url = useEmbeddedVideoUrl(mediaPayload.embedUrl, {
    autoplay: 1,
    controls: 0,
    loop: loop ? 1 : 0,
    muted: 1,
    playsinline: 1,
    ...options,
  })

  const posterImage = mediaPayload.previewImage

  const height = posterImage?.height || 1
  const width = posterImage?.width || 1
  const ratio = (height / width) * 100

  const containerStyles = (() => {
    const paddingBottom = `${ratio}%`
    return layout === 'responsive' ? { paddingBottom } : undefined
  })()

  return (
    <div style={containerStyles} className={s.container({ layout })}>
      {posterImage?.url && (
        <Image
          src={posterImage?.url}
          priority={priority}
          layout={'fill'}
          alt={posterImage.altText || ''}
        />
      )}

      <iframe
        className={s.iframe}
        title={mediaPayload.alt ?? mediaPayload.id}
        id={id ?? mediaPayload.embedUrl}
        frameBorder={frameBorder}
        allow={allow}
        allowFullScreen={allowFullScreen}
        src={url}
        loading={loading}
      />
    </div>
  )
}
