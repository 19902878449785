'use client'

import { HTMLProps, PropsWithChildren, forwardRef } from 'react'
import { clickable, ClickableVariants } from '../clickable.css'
import cx from 'classnames'

type ButtonProps = PropsWithChildren<
  ClickableVariants &
    Omit<HTMLProps<HTMLButtonElement>, 'type'> & {
      type?: 'button' | 'submit' | 'reset'
    }
>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, appearance, type, className, ...restOfProps }, ref) => {
    return (
      <button
        ref={ref}
        className={cx(
          clickable({
            appearance,
          }),
          className,
        )}
        {...restOfProps}
      >
        {children}
      </button>
    )
  },
)
Button.displayName = 'Button'
