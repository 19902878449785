'use client'

import { FC, HTMLProps, PropsWithChildren } from 'react'
import NextLink, { LinkProps } from 'next/link'
import { clickable, ClickableVariants } from '../clickable.css'
import { LinkAction } from './LinkAction'
import { LinkActions, LinkPayload } from '@pienso/models'
import cx from 'classnames'

type _ProjectLinkProps = ClickableVariants &
  Omit<LinkProps, 'href'> &
  Omit<
    HTMLProps<HTMLAnchorElement | HTMLButtonElement>,
    'ref' | 'type' | 'action'
  > &
  LinkPayload

export type ProjectLinkProps = PropsWithChildren<_ProjectLinkProps>

export const Link: FC<ProjectLinkProps> = ({
  children,
  appearance,
  className,
  accessibilityLabel,
  // LINK PROPS START
  href,
  as,
  replace,
  shallow,
  passHref,
  prefetch,
  locale,
  legacyBehavior,
  //LINK PROPS END
  ...elementProps
}) => {
  const action: LinkActions | undefined =
    'action' in elementProps ? elementProps.action : undefined

  if (href && action) {
    throw new Error('Link cannot take both href and action props')
  }

  const classes = cx(
    clickable({
      appearance,
    }),
    className,
  )

  if (href) {
    const NEXT_LINK_PROPS = {
      href,
      as,
      replace,
      shallow,
      passHref,
      prefetch,
      locale,
      legacyBehavior,
    }

    return (
      <NextLink
        className={classes}
        {...NEXT_LINK_PROPS}
        aria-label={accessibilityLabel}
        {...elementProps}
      >
        {children}
      </NextLink>
    )
  }

  if ('action' in elementProps && elementProps.action) {
    const { action, ...restElementProps } = elementProps

    return (
      <LinkAction
        action={action}
        renderButton={(actionProps) => (
          <button className={classes} {...actionProps} {...restElementProps}>
            {children}
          </button>
        )}
      />
    )
  }

  return null
}
