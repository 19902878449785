export function formatTimeNumber(time: number, leadingZero = true): string {
  const value = time.toString()
  if (leadingZero) {
    return value.padStart(2, '0')
  }
  return value
}

export function convertTime(time: string, clock: '12' | '24' = '12'): string {
  const [h, m] = time.split(':').map((v) => parseInt(v))
  const date = new Date()
  date.setHours(h, m)
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: clock === '12',
  })
}

const dateRegex = /(\d{4})-(\d{2})-(\d{2})/

export function isoDateToDate(date: string) {
  const [matched, year, month, day] = date.match(dateRegex) || []
  if (!matched) return new Date(date)
  return new Date(Number(year), Number(month) - 1, Number(day))
}
