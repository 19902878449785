import { LinkField, type PortableTextBlocks } from '@pienso/models'
import { typography, type TypographyWeightVariant } from '@pienso/styles'
import {
  PortableTextMarkComponentProps,
  PortableText as SanityPortableText,
} from '@portabletext/react'
import { PropsWithChildren } from 'react'
import { TypedObject } from 'sanity'
import { Link } from '../Clickable/Link/Link'
import { common } from './SingleBlockPortableText.css'

export type SingleBlockPortableTextProps = {
  value?: PortableTextBlocks
  as: 'h1' | 'h2' | 'h6' | 'p'
  appearance: keyof typeof typography
  weight?: TypographyWeightVariant
  withMargin?: boolean
}

interface MarkComponent<T extends TypedObject> {
  (props: PropsWithChildren<PortableTextMarkComponentProps<T>>): JSX.Element
}

const linkMark: MarkComponent<LinkField & { _type: string }> = ({
  value,
  children,
}) => {
  return (
    <Link {...value?.payload} appearance={'richTextLinkBlue'}>
      {children}
    </Link>
  )
}

export const SingleBlockPortableText = ({
  value,
  as: Component = 'h1',
  appearance = 'heading1',
  weight = 'regular',
  withMargin = false,
}: SingleBlockPortableTextProps) => {
  const typographyVariant = typography[appearance]

  if (!typographyVariant) {
    console.error(`No typography found for appearance: ${appearance}`)
    return null
  }

  const itemClass = `${typographyVariant({ weight })} ${common({
    withMargin,
  })}`

  return value ? (
    <div>
      <SanityPortableText
        value={value}
        components={{
          block: {
            normal: ({ children }) => (
              <Component className={itemClass}>{children}</Component>
            ),
          },
          marks: {
            link: linkMark,
            textColor: ({ children, value }) => {
              return (
                <span
                  style={{ color: value.hex }}
                  className={typographyVariant({ weight })}
                >
                  {children}
                </span>
              )
            },
            textHighlight: ({ children, value }) => {
              return (
                <span
                  style={{ background: value.hex }}
                  className={typographyVariant({ weight })}
                >
                  {children}
                </span>
              )
            },
          },
        }}
      />
    </div>
  ) : null
}
