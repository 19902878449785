import { forwardRef, useEffect, useRef } from 'react'
import { MediaLayoutType } from '../models/MediaProps'
import { MuxImage } from './MuxImage'
import { MuxImageMediaPayload } from '@pienso/models'
import { MediaRatiosTypeExtended, ratios } from '../ratios'
import { captureException } from '@sentry/nextjs'
import * as s from './Viedo.css'

type Props = {
  layout?: MediaLayoutType
  width: number
  height: number
  firstFrame: string
  priority?: boolean
  alt?: string
  preload?: string
  autoplay?: boolean
  muted?: boolean
  loop?: boolean
  src?: string
  play?: boolean
  load?: boolean
  sizes?: string
  onEnded?: () => void
  onPlaying?: () => void
  onPause?: () => void
  onCanPlay?: () => void
  onLoadedData?: () => void
  onWaiting?: () => void
  onError?: () => void
  onLoadStart?: () => void
  onNotAllowed?: () => void
  ratio?: MediaRatiosTypeExtended
}

export const Video = forwardRef<HTMLDivElement, Props>(
  (
    {
      layout,
      width,
      height,
      sizes,
      firstFrame,
      priority,
      alt,
      loop = true,
      muted = true,
      preload = 'none',
      autoplay = false,
      src,
      play,
      load,
      onPlaying,
      onPause,
      onCanPlay,
      onLoadedData,
      onLoadStart,
      onWaiting,
      onEnded,
      onNotAllowed,
      onError,
      ratio,
    },
    ref,
  ) => {
    const containerStyles = (() => {
      if (layout !== 'responsive') return undefined

      if (ratio && typeof ratio === 'string') {
        const ratioValue = ratios[ratio]
        const paddingBottom = `${ratioValue * 100}%`
        return { paddingBottom }
      }

      const originalRatio = `${(height / width) * 100}%`
      return { paddingBottom: originalRatio }
    })()

    const playRequestInProgress = useRef(false)
    const videoRef = useRef<HTMLVideoElement>(null)

    const firstFramePayload: MuxImageMediaPayload = {
      type: 'mux-image',
      image: {
        src: firstFrame,
        alt,
      },
    }

    /**
     *  when state of -play- changes trigger play() or pause() method to <video>
     **/
    useEffect(() => {
      if (play === undefined) return

      const videoEl = videoRef.current
      const handler = async () => {
        if (videoEl && play) {
          playRequestInProgress.current = true
          await videoEl.play().catch((err: Error) => {
            if (err.name === 'NotAllowedError') {
              /**
               *   Not allowed error occurs when browser is blocking play() - Low Power Mode case
               *   on not allowed is function that should reset state of video to initial
               **/
              onNotAllowed && onNotAllowed()
            } else {
              captureException(err)
              onError && onError()
            }
          })
          playRequestInProgress.current = false
          return
        }
        /**
         *   Pause only when play() method is done
         **/
        if (videoEl && !play && !playRequestInProgress.current) {
          videoEl?.pause()
        }
      }
      handler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [play, src])

    /**
     *  when state of load=true changes trigger load()
     **/
    useEffect(() => {
      if (load === undefined) return

      const videoEl = videoRef.current
      if (videoEl && load) {
        videoEl?.load()
        return
      }
    }, [load])

    return (
      <div
        style={containerStyles}
        className={s.videoWrap({ layout })}
        ref={ref}
      >
        <MuxImage
          mediaPayload={firstFramePayload}
          priority={priority}
          layout={'fill'}
          sizes={sizes}
          originalWidth={width}
          style={{
            mixBlendMode: 'lighten',
          }}
        />
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          className={s.videoElement}
          ref={videoRef}
          preload={preload}
          autoPlay={autoplay}
          muted={muted}
          loop={loop}
          playsInline
          title={alt}
          src={src}
          onEnded={onEnded}
          onPlaying={onPlaying}
          onPause={onPause}
          onCanPlayThrough={onCanPlay}
          onWaiting={onWaiting}
          onLoadedData={onLoadedData}
          onLoadStart={onLoadStart}
        />
      </div>
    )
  },
)
