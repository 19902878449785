'use client'
import { useEffect, useState } from 'react'
import { breakpoints } from './breakpoints.css'

type Breakpoints = keyof typeof breakpoints

export function useMediaQuery(mediaQuery: Breakpoints): boolean {
  const [matches, setMatches] = useState<boolean>(false)
  const mediaSize = breakpoints[mediaQuery]

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => {
      setMatches(e.matches)
    }

    const mediaQueryList = window.matchMedia(mediaSize)
    setMatches(mediaQueryList.matches)

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', handler)

      return () => {
        mediaQueryList.removeEventListener('change', handler)
      }
    } else {
      mediaQueryList.addListener(handler)

      return () => {
        mediaQueryList.removeListener(handler)
      }
    }
  }, [matches])

  return matches
}
