'use client'

import { FC } from 'react'
import NextImage, { ImageProps } from 'next/image'
import {
  MediaLayoutType,
  MediaObjectFitType,
  MediaObjectPositionType,
} from '../models/MediaProps'

export const Image: FC<
  Omit<ImageProps, 'layout' | 'objectPosition' | 'objectFit'> & {
    objectFit?: MediaObjectFitType
    layout?: MediaLayoutType
    objectPosition?: MediaObjectPositionType
  }
> = ({
  layout,
  alt,
  width,
  src,
  height,
  objectFit,
  sizes,
  priority,
  placeholder,
  loader,
  blurDataURL,
  objectPosition,
  className,
  unoptimized,
  fetchPriority,
  style,
}) => {
  const isFillLayout = layout === 'fill'
  const isResponsiveLayout = layout === 'responsive'
  const isResponsiveCoverLayout = layout === 'responsive-cover'

  return (
    <NextImage
      fetchPriority={fetchPriority}
      src={src}
      fill={isFillLayout}
      width={isFillLayout ? undefined : width}
      height={isFillLayout ? undefined : height}
      alt={alt}
      loader={loader}
      sizes={sizes}
      placeholder={placeholder}
      blurDataURL={blurDataURL}
      priority={priority}
      className={className}
      unoptimized={unoptimized}
      style={{
        ...(isFillLayout ? { objectFit: 'cover' } : {}),
        ...(objectFit ? { objectFit } : {}),
        ...(objectPosition ? { objectPosition } : {}),
        ...(isResponsiveLayout ? { width: '100%', height: 'auto' } : {}),
        ...(isResponsiveCoverLayout ? { width: '100%', height: '100%' } : {}),
        ...(style || {}),
      }}
    />
  )
}
