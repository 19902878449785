import { CSSProperties, FC } from 'react'
import { ImageLoader } from 'next/image'

import { MediaProps } from '../models/MediaProps'
import { Image } from './Image'

export const MuxImage: FC<
  MediaProps & { originalWidth: number; style?: CSSProperties }
> = ({ mediaPayload, priority, sizes, layout, originalWidth, style }) => {
  if (mediaPayload?.type !== 'mux-image' || !mediaPayload.image) {
    return null
  }
  const { image } = mediaPayload

  const loader: ImageLoader = ({ width, src }) => {
    const params = new URLSearchParams()
    const computedWidth = width > originalWidth ? originalWidth : width

    if (computedWidth) {
      params.append('width', `${computedWidth}`)
    }

    return `${src}&${params.toString()}`
  }

  return (
    <Image
      alt={image.alt || ''}
      objectFit={'cover'}
      priority={priority}
      src={mediaPayload.image.src}
      sizes={sizes}
      layout={layout}
      loader={loader}
      placeholder={'empty'}
      style={style}
    />
  )
}
