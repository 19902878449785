'use client'

import { LinkActions } from '@pienso/models'
import { FC } from 'react'
import { type TooltipContentColor } from '../../Tooltip/Tooltip'
import * as s from './LinkAction.css'
import dynamic from 'next/dynamic'

const Tooltip = dynamic(() =>
  import('../../Tooltip/Tooltip').then((mod) => mod.Tooltip),
)
const TooltipContent = dynamic(() =>
  import('../../Tooltip/Tooltip').then((mod) => mod.TooltipContent),
)
const TooltipTrigger = dynamic(() =>
  import('../../Tooltip/Tooltip').then((mod) => mod.TooltipTrigger),
)

type ActionProps = {
  onClick: () => void
}

type LinkActionProps = {
  action: LinkActions
  renderButton: (actionProps: ActionProps) => JSX.Element
}

export const LinkAction: FC<LinkActionProps> = ({ action, renderButton }) => {
  switch (action.type) {
    case 'linkPayloadActionTooltip': {
      const { content, color = 'green' } = action.payload

      const actionProps = {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick: () => {},
      }

      return (
        <Tooltip>
          <TooltipTrigger>{renderButton(actionProps)}</TooltipTrigger>
          <TooltipContent color={color as TooltipContentColor}>
            <span className={s.tooltipContent}>{content}</span>
          </TooltipContent>
        </Tooltip>
      )
    }
    case 'linkPayloadActionModal': {
      const actionProps = {
        onClick: () => alert(action.payload.modalContent),
      }
      return <>{renderButton(actionProps)}</>
    }
  }
}
