'use client'

import { FC, ReactElement, useEffect } from 'react'
import { MuxVideoMediaPayload, MuxVideoType } from '@pienso/models'
import { MediaProps } from '../models/MediaProps'
import { Video } from './Video'
import { useVideoOrchestrator } from '../helpers/useVideoOrchestrator'
import { VideoControlButtons } from './VideoControlButtons'

type MuxVideoProps = Omit<MediaProps, 'mediaPayload'> & {
  mediaPayload: MuxVideoMediaPayload
  videoOverride?: (video: MuxVideoType) => ReactElement
}
type VideoAdapterProps = Omit<MediaProps, 'mediaPayload'> & {
  video: MuxVideoType
}

const VideoAdapter: FC<VideoAdapterProps> = ({
  layout,
  priority,
  video,
  loop,
  muted = true,
  controlsPosition,
  autoplay = true,
  sizes,
  className,
  ratio,
  objectFit,
}) => {
  const { firstFrame, mp4, width, height, alt } = video

  useEffect(() => {
    objectFit === 'contain' &&
      console.warn('object-fit:contain not working with video!')
  }, [objectFit])

  const { containerRef, videoControlProps, controlsProps } =
    useVideoOrchestrator({
      autoplay,
      isMuted: muted,
    })

  return (
    <div ref={containerRef} className={className}>
      <Video
        src={mp4}
        width={width}
        height={height}
        layout={layout}
        sizes={sizes}
        firstFrame={firstFrame}
        alt={alt}
        loop={loop}
        priority={priority}
        ratio={ratio}
        {...videoControlProps}
      />
      <VideoControlButtons
        {...controlsProps}
        controlsPosition={controlsPosition}
        withSoundControl={false}
      />
    </div>
  )
}

export const MuxVideo = ({
  mediaPayload,
  videoOverride,
  ...restOfProps
}: MuxVideoProps) => {
  const { video } = mediaPayload

  if (videoOverride) {
    return videoOverride(video)
  }

  return <VideoAdapter video={video} {...restOfProps} />
}
