'use-client'

import { FC, MouseEventHandler } from 'react'
import { VideoControlsPositionType } from '../models/MediaProps'
// import { Play, Pause, SoundOn, SoundOff } from '@pienso/icons'
// import {
//   controlButton,
//   controlWrapper,
//   playControl,
//   soundControl,
//   spinner,
//   spinnerWrap,
// } from './VideoControlButtons.css'

type VideoControlButtonsProps = {
  isPlaying?: boolean
  muted?: boolean
  isLoading?: boolean
  withSoundControl?: boolean
  controlsPosition?: VideoControlsPositionType
  onRequestPause: MouseEventHandler<HTMLButtonElement>
  onRequestPlay: MouseEventHandler<HTMLButtonElement>
  onRequestUnmute: MouseEventHandler<HTMLButtonElement>
  onRequestMute: MouseEventHandler<HTMLButtonElement>
}

export const VideoControlButtons: FC<VideoControlButtonsProps> = () =>
  // {
  // isPlaying,
  // isLoading,
  // muted,
  // onRequestPause,
  // onRequestPlay,
  // onRequestUnmute,
  // onRequestMute,
  // controlsPosition = 'none',
  // withSoundControl = false,
  // },
  {
    return null
    // return (
    //   <div
    //     className={controlWrapper({
    //       controlsPosition,
    //     })}
    //   >
    //     <div
    //       className={spinnerWrap({
    //         position: controlsPosition,
    //       })}
    //     >
    //       <span
    //         className={spinner({
    //           isLoading,
    //         })}
    //       />
    //     </div>
    //     {withSoundControl && (
    //       <div className={soundControl}>
    //         {muted ? (
    //           <button
    //             className={controlButton}
    //             onClick={onRequestUnmute}
    //             aria-label="unmute"
    //           >
    //             <SoundOff />
    //           </button>
    //         ) : (
    //           <button
    //             className={controlButton}
    //             onClick={onRequestMute}
    //             aria-label="mute"
    //           >
    //             <SoundOn />
    //           </button>
    //         )}
    //       </div>
    //     )}
    //     <div className={playControl}>
    //       {isPlaying ? (
    //         <button
    //           className={controlButton}
    //           onClick={onRequestPause}
    //           aria-label="pause"
    //         >
    //           <Pause />
    //         </button>
    //       ) : (
    //         <button
    //           className={controlButton}
    //           onClick={onRequestPlay}
    //           aria-label="play"
    //         >
    //           <Play />
    //         </button>
    //       )}
    //     </div>
    //   </div>
    // )
  }
