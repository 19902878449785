'use client'
import { FC } from 'react'
import { SanityImage } from './components/SanityImage'
import { MuxVideo } from './components/MuxVideo'
import { MediaProps } from './models/MediaProps'
import { ShopifyImage } from './components/ShopifyImage'
import { ShopifyVideo } from './components/ShopifyVideo'
import { ShopifyExternalVideo } from './components/ShopifyExternalVideo'
import { SplineScene } from './components/SplineScene/SplineScene'
import cx from 'classnames'
import * as s from './Media.css'

type MediaAdapterProps = Omit<
  MediaProps,
  'videoOverrideMobile' | 'mediaPayloadMobile'
> & {
  isMobile?: boolean
  isMobileAndDesktop?: boolean
}

export const MediaAdapter: FC<MediaAdapterProps> = ({
  layout = 'responsive',
  objectFit = 'cover',
  sizes,
  priority,
  mediaPayload,
  loop,
  autoplay,
  muted,
  ratio,
  controlsPosition,
  videoOverride,
  className,
  isMobile,
  isMobileAndDesktop,
}) => {
  // useEffect(() => {
  //   if (typeof ratio !== 'string' && sizes) {
  //     console.warn('Do not pass sizes when ratio is object!')
  //   }
  // }, [])

  switch (mediaPayload?.type) {
    case 'sanity-image':
      return (
        <SanityImage
          mediaPayload={mediaPayload}
          sizes={sizes}
          objectFit={objectFit}
          priority={priority}
          layout={layout}
          ratio={ratio}
          className={className}
        />
      )
    case 'mux-video':
      return (
        <MuxVideo
          mediaPayload={mediaPayload}
          layout={layout}
          priority={priority}
          ratio={ratio}
          objectFit={objectFit}
          loop={loop}
          sizes={sizes}
          muted={muted}
          controlsPosition={controlsPosition}
          autoplay={autoplay}
          videoOverride={videoOverride}
          className={className}
        />
      )
    case 'shopify-image':
      return (
        <ShopifyImage
          sizes={sizes}
          objectFit={objectFit}
          mediaPayload={mediaPayload}
          layout={layout}
          priority={priority}
          loop={loop}
          ratio={ratio}
          className={className}
        />
      )
    case 'shopify-media-video': {
      return (
        <ShopifyVideo
          mediaPayload={mediaPayload.video}
          layout={layout}
          priority={priority}
          loop={loop}
        />
      )
    }
    case 'shopify-media-external-video': {
      return (
        <ShopifyExternalVideo
          mediaPayload={mediaPayload.video}
          layout={layout}
          priority={priority}
          loop={loop}
        />
      )
    }

    case 'spline-scene': {
      return (
        <SplineScene
          {...mediaPayload.scene}
          className={className}
          isMobile={isMobile}
          isMobileAndDesktop={isMobileAndDesktop}
          sizes={sizes}
        />
      )
    }

    default:
      return <div>Unsupported media source</div>
  }
}

export const Media: FC<MediaProps> = ({
  mediaPayload,
  mediaPayloadMobile,
  layout,
  videoOverride,
  videoOverrideMobile,
  className,
  ...restOfProps
}) => {
  if (!mediaPayload) {
    return null
  }

  return (
    <div className={cx(s.responsiveWrap({ layout }), className)}>
      <MediaAdapter
        mediaPayload={mediaPayload}
        layout={layout}
        {...restOfProps}
        videoOverride={videoOverride}
        className={s.desktopMedia({
          shouldHideOnMobile: Boolean(mediaPayloadMobile),
        })}
        isMobileAndDesktop={Boolean(!mediaPayloadMobile)}
      />
      {mediaPayloadMobile && (
        <MediaAdapter
          mediaPayload={mediaPayloadMobile}
          layout={layout}
          {...restOfProps}
          videoOverride={videoOverrideMobile}
          className={s.mobileMedia}
          isMobile={true}
        />
      )}
    </div>
  )
}
