export type ImageLoaderProps = {
  width: number
  src: string
  height?: number
  cropTo?: 'center'
  quality?: number
  imageExtension?: string
}

export const shopifyImageLoader = (props: ImageLoaderProps): string => {
  const { width, height, src, cropTo = 'center' } = props

  const params = [
    ...(width ? [`width=${width}`] : []),
    ...(height ? [`height=${height}`] : []),
    ...(cropTo ? [`crop=${cropTo}`] : []),
    // 'scale=2'
  ]
  const paramsString = params.join('&')

  const url = src + '&' + paramsString
  return url
}
