import { FC, ReactNode } from 'react'
import * as styles from './layout.css'

type WrapperProps = {
  id?: string
  children: ReactNode
  as?: keyof HTMLElementTagNameMap
}

export const ContentContainer: FC<WrapperProps> = ({ as, children }) => {
  const Wrapper = as || 'div'

  return <Wrapper className={styles.contentContainer}>{children}</Wrapper>
}
