'use client'

/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useContext, useState } from 'react'

const StickyContentContext = createContext<{
  isDisabled: boolean
  disable: () => void
  enable: () => void
}>({ isDisabled: false, disable: () => {}, enable: () => {} })

function StickyContentProvider({ children }: { children: React.ReactNode }) {
  const [isDisabled, setIsDisabled] = useState(false)

  const disable = useCallback(() => {
    setIsDisabled(true)
  }, [])

  const enable = useCallback(() => {
    setIsDisabled(false)
  }, [])

  return (
    <StickyContentContext.Provider
      value={{
        isDisabled,
        disable,
        enable,
      }}
    >
      {children}
    </StickyContentContext.Provider>
  )
}

function useStickyContent() {
  return useContext(StickyContentContext)
}

export { StickyContentProvider, useStickyContent }
