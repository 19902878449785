export const ratios = {
  square: 1,
  landscape1: 0.42,
  landscape2: 0.64,
  portrait1: 1.1,
  portrait2: 1.32,
  largeExtraPromo: 1.25,
  smallExtraPromo: 1.75,
  sliderImage0: 0.66,
  sliderImage1: 1.08,
  sliderImage2: 0.87,
}
export type ImageSizes =
  | '420'
  | '768'
  | '1024'
  | '1400'
  | '1600'
  | '1920'
  | '2560'

export type MediaRatiosType = keyof typeof ratios

export type MediaRatiosTypeExtended =
  | MediaRatiosType
  | Record<ImageSizes, keyof typeof ratios>
